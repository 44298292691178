import { LoginAuthModule, AdminAuthInterceptorService } from '@mosaic-apps/login-auth';
import { UserAuthModule } from '@mosaic-apps/user-auth';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE
} from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from 'src/app/core/services/interceptors/loader.interceptor';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { LayoutModule } from 'src/app/layout/layout.module';
import { JsonLoaderService } from 'src/app/core/services/json-loader.service';
import { MomentUtcDateAdapter } from 'src/app/shared/material/moment-utc-date-adapter';
import { ErrorHandlerInterceptor } from './core/services/interceptors/error-handler.interceptor';

// in-built module

export function loadConfigFactory(jsonLoaderService: JsonLoaderService) {
    return () => jsonLoaderService.loadConfigJson();
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        UserAuthModule,
        AppRoutingModule,
        SharedModule,
        CoreModule,
        LayoutModule,
        LoginAuthModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfigFactory,
            deps: [JsonLoaderService],
            multi: true
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }, // work around for date picker issue with native controler
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AdminAuthInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
